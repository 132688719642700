<script setup lang="ts">
import Popper from 'vue3-popper/dist/popper.esm'

// Props
export type TooltipPlacement = 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'right'

interface TooltipProps {
  placement?: TooltipPlacement
  arrow?: boolean
  text: string
  subtext?: string
  showIcon?: boolean
}

const props = withDefaults(defineProps<TooltipProps>(), {
  placement: 'bottom',
  arrow: false,
  showIcon: false,
})

// Styles

const popperSkid = (() => {
  if (props.placement.includes('-start'))
    return '-20'
  else if (props.placement.includes('-end'))
    return '20'
  else return '0'
})()
</script>

<template>
  <Popper
    :hover="true"
    :placement="props.placement"
    :close-delay="100"
    :arrow="props.arrow"
    :offset-skid="popperSkid"
    offset-distance="12"
  >
    <div flex gap-4px items-center>
      <slot />
      <div v-if="showIcon" class="tooltip-icon">
        <UnoIcon i-fubex-i-circle />
      </div>
    </div>
    <template #content>
      <div max-w-370px>
        <div paragraph-sm text-neutral-50 dark:text-neutral-800>
          {{ props.text }}
        </div>
        <div v-if="props.subtext" pt-4px paragraph-xs text-neutral-300>
          {{ props.subtext }}
        </div>
        <div class="tooltip-content" text-neutral-400 mt-12px dark:text-neutral-600>
          <slot name="tooltip-content" />
        </div>
      </div>
    </template>
  </Popper>
</template>

<style>
/* Themed Tailwind classes would be 'bg-fill-system-selected fubex-rounded-md fubex-shadow-lg'  */
:root {
  --popper-theme-background-color: #1D1D1F;
  --popper-theme-background-color-hover: #1D1D1F;
  --popper-theme-text-color: inherit;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: transparent;
  --popper-theme-border-radius: 8px;
  --popper-theme-padding: 12px;
  --popper-theme-box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}
</style>
